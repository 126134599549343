<template>
  <b-card no-body>
    <b-card-header>
      <h4>معلومات الحزمة</h4>
    </b-card-header>
    <b-card-body>
      <validation-observer ref="setPackage">
        <b-form>
          <b-row>
            <b-col cols="12" lg="8">
              <b-row class="align-items-center">
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="اسم الحزمة" label-for="packageName">
                    <validation-provider
                      v-slot="{ errors }"
                      name="اسم الحزمة"
                      rules="required"
                    >
                      <b-form-input
                        id="packageName"
                        v-model="packageForm.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="نوع الحزمة" label-for="packageType">
                    <validation-provider
                      v-slot="{ errors }"
                      name="نوع الحزمة"
                      rules="required"
                    >
                      <v-select
                        id="packageType"
                        v-model="packageForm.packageType"
                        :state="errors.length > 0 ? false : null"
                        label="name"
                        :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                        :options="packageTypes"
                        :reduce="(item) => item.id"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex" cols="6" md="4" lg="2">
                  <label class="mx-1">حالة الحزمة</label>
                  <b-form-checkbox
                    class="custom-control-primary"
                    v-model="packageForm.isHidden"
                    switch
                  >
                    <span class="switch-icon-left"> مخفي </span>
                    <span class="switch-icon-right"> ظاهر </span>
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="تاريخ البداية" label-for="startDate">
                    <validation-provider
                      v-slot="{ errors }"
                      name="تاريخ البداية"
                      rules="required"
                    >
                      <flat-pickr
                        id="startDate"
                        v-model="packageForm.startDate"
                        class="form-control"
                        :config="config"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="تاريخ النهاية" label-for="endDate">
                    <validation-provider
                      v-slot="{ errors }"
                      name="تاريخ النهاية"
                      rules="required"
                    >
                      <flat-pickr
                        id="endDate"
                        v-model="packageForm.endDate"
                        class="form-control"
                        :config="config"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group label="وصف الحزمة" label-for="description">
                <b-form-textarea
                  id="description"
                  v-model="packageForm.description"
                  rows="4"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card-header>
            <h4>محتويات الحزمة</h4>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <vue-colabse-table
                label="label"
                align="right"
                :headers="header"
                @changeSelect="calcolateTotale"
                :colapseOptions="{
                  enable: true,
                  childrenLabel: 'units',
                  enableCustomHeadre: true,
                  customHeaderLabel: 'label',
                  header: [
                    {
                      label: 'اسم الوحدة',
                      value: 'unitName',
                    },
                    {
                      label: 'نسبة الأستاذ',
                      value: 'teacherRate',
                    },
                    {
                      label: 'السعر',
                      value: 'price',
                    },
                  ],
                }"
                :value="packageForm.packageUnits"
                :selectOptions="{
                  enable: true,
                  label: 'selected',
                }"
                :items="subjectsUnits"
                lableId="unitId"
                borderd
              >
                <template v-slot:colapse-icon>
                  <unicon name="angle-up"></unicon>
                </template>
                <template v-slot:item-subjectPrice="{ val }">
                  <div
                    class="d-flex justify-content-between"
                    style="width: 80px"
                  >
                    {{ val }} <small> ل.س</small>
                  </div>
                </template>
                <template v-slot:item-th.teacherRate="{ tr }">
                  <td>
                    <b-input-group size="sm" append="%" style="width: 100px">
                      <b-form-input
                        type="number"
                        max="100"
                        min="0"
                        v-model.number="tr.teacherRate"
                      ></b-form-input>
                    </b-input-group>
                  </td>
                </template>
                <template v-slot:item-th.price="{ tr }">
                  <td>
                    <b-input-group size="sm" append="ل.س" style="width: 140px">
                      <b-form-input
                        type="number"
                        @change="calcolateTotaleChangeInput(tr, $event)"
                        :value="tr.price"
                      ></b-form-input>
                    </b-input-group>
                  </td>
                </template>
              </vue-colabse-table>
              <b-card
                no-body
                class="m-0 border border-top-0"
                style="border-top-left-radius: 0; border-top-right-radius: 0"
              >
                <b-card-body class="p-1">
                  <span class="d-flex ml-auto" style="width: 150px">
                    <small class="pr-1">كلفة الحزم</small>
                    {{ packageForm.price }}
                    <small class="ml-auto">ل.س</small>
                  </span>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
    <b-card-footer class="d-flex">
      <b-button
        v-if="id == 0"
        size="md"
        type="submit"
        variant="primary"
        class="mr-1"
        @click.prevent="SubmitSetPackage()"
      >
        إضافة
      </b-button>
      <b-button
        v-if="id > 0"
        size="md"
        type="submit"
        variant="primary"
        class="mr-1"
        @click.prevent="SubmitSetPackage()"
      >
        تعديل
      </b-button>
      <b-button
        class="mr-1"
        to="/codes-packages/packages"
        size="md"
        variant="outline-primary"
      >
        تراجع
      </b-button>
      <b-button
        @click.prevent="SubmitRemovePackage()"
        size="md"
        variant="danger"
      >
        حذف
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<style>
[dir="rtl"] .custom-switch .custom-control-label::before {
  width: 4rem;
}
[dir="rtl"] .custom-switch .custom-control-label .switch-text-right,
[dir="rtl"] .custom-switch .custom-control-label .switch-icon-right {
  left: -1px;
}
[dir="rtl"] .custom-switch .custom-control-label .switch-text-left,
[dir="rtl"] .custom-switch .custom-control-label .switch-icon-left {
  right: 6px;
}
[dir="rtl"]
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(-2.4rem);
  transform: translateX(-2.4rem);
}
</style>

<script>
import vueColabseTable from "@core/components/vue-colabse-table/vue-colabse-table";
import {
  BInputGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BFormCheckbox,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
// import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
    components: {
        vueColabseTable,
        BInputGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BForm,
        vSelect,
        flatPickr,
        BFormTextarea,
        BButton,
        // VueGoodTable
    },
    data() {
        return {
            required,
            pageLength: 10,
            config : {
                allowInput : true
            },
            header: [
                {
                    label: "اسم المادة",
                    value: "subjectName"
                },
                {
                    label: "السعر",
                    value: "subjectPrice"
                }
            ]
        };
    },
    directives: {
        "b-toggle": VBToggle
    },
    props: {
        id: String
    },
    mounted() {
        localize("ar");
        this.fetchPackageDetails(this.id);
    },
    computed: {
        ...mapGetters(["packageForm", "packageTypes", "subjectsUnits"])
    },
    methods: {
        ...mapActions(["fetchPackageDetails", "setPackage","removePackage"]),
        SubmitSetPackage() {
            // this.setPackage();
            this.$refs.setPackage.validate().then(success => {
                if (success) {
                    this.setPackage(this.packageForm);
                    if (this.id == 0) {
                        this.$bvToast.toast(
                            `تم إضافة الحزمة ${this.packageForm.name} بنجاح`,
                            {
                                title: "تم الإضافة",
                                variant: "success",
                                toaster: "b-toaster-bottom-left",
                                solid: true,
                                autoHideDelay: 2000,
                                appendToast: true
                            }
                        );
                    } else {
                        this.$bvToast.toast(
                            `تم تعديل الحزمة ${this.packageForm.name} بنجاح`,
                            {
                                title: "تم التعديل",
                                variant: "success",
                                toaster: "b-toaster-bottom-left",
                                solid: true,
                                autoHideDelay: 2000,
                                appendToast: true
                            }
                        );
                    }
                }
            });
        },
        selectionChanged() {
            this.isNotSelectedRows = !this.$refs["package-content-table"].selectedRows.length;
        },
        getSelectedRow() {
            console.log(this.$refs["package-content-table"].selectedRows);
        },
        calcolateTotale(tr) {
            this.subjectsUnits.forEach((subj) => {
                subj.units.forEach((unit) => {
                    if(unit.unitId == tr.unitId) {
                        this.packageForm.price = unit.selected ? this.packageForm.price + +unit.price: this.packageForm.price - +unit.price
                    }
                })
            })
        },
        calcolateTotaleChangeInput(tr, val) {
            if(tr.selected) {
                if(tr.price > +val) {
                    this.packageForm.price -= (tr.price - +val)
                } else {
                    this.packageForm.price += (+val - tr.price)
                }
            }
            tr.price = +val
        },
        SubmitRemovePackage(){
            const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text:
            "قد يؤدي حذف الحزمة لحذف حسابات مالية غير معالجة، يرجى التأكد من ذلك قبل الحذف.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removePackage([+this.id]);
          }
        });
    },
  },
};
</script>
